import React from 'react';
import {Link} from 'gatsby';
import Helmet from 'react-helmet';
import ReactFitText from 'react-fittext';
import ProgrammeSteps from './ProgrammeSteps';
import '../../Content/css/programme.css'
import PageHeader from '../PageComponents/PageHeader';
import requireAuth from "../Authentication/RequireAuthentication";
let gravURL = process.env.REACT_APP_GRAV_URL;
let siteURL = process.env.REACT_APP_APP_URL;
class ProgrammePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gravContent: this.props.pageContext.frontmatter,
      uploads: gravURL + '/uploads/images/programme/',
      loading: true,
      bannerImage: '',
      headContent: this.props.pageContext.head,
      media: this.props.pageContext.media,
      mobileDevice: false
    }
  }
  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {

      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions.bind(this));
      this.setState({loading: false})
    }
  }
  static getDerivedStateFromProps(props, state) {
    //
    return null
  }
  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  updateWindowDimensions() {
    if (window.innerWidth > 768) {
      this.setState({mobileDevice: false})
    }
    if (window.innerWidth <= 767) {
      this.setState({mobileDevice: true})
    }

  }

  render() {
    let {
      url,
      facebookTitle,
      shareImg,
      googleDesc,
			facebookDesc,
			keyWords,
			title
    } = this.state.headContent;
    let mobileDevice = this.state.mobileDevice === true && this.state.media.desktopBanner;
    return (

      <main>
        <Helmet>
					<title>Products | New Image Keto | Alpha Lipid™ SDII Programme</title>	
					{googleDesc&&<meta name="description" content={googleDesc}/>}
					{keyWords&& <meta name="keywords" content={keyWords.toString()} />}
					<meta property="og:image:width" content="1200" />
					<meta property="og:image:height" content="630" />
					<meta property="og:url" content={url?siteURL + url:siteURL} />
					<meta property="og:title" content={facebookTitle?facebookTitle:title}/>
					<meta name="twitter:title" content={facebookTitle?facebookTitle:title}/>
					<meta property="og:description" content={facebookDesc?facebookDesc:googleDesc} />
					<meta name="twitter:description" content={facebookDesc?facebookDesc:googleDesc}/>
					<meta property="og:image" content={shareImg} />
					<meta property="og:image:url" content={shareImg}/>
					<meta name="twitter:image" content={shareImg}/>
					<meta name="twitter:card" content={shareImg}/>
				</Helmet>
        <div className="programmePageWrapper clearfix">
          <section>
            <PageHeader
              mainHeading={this.state.gravContent.pageHeading}
              subHeading={this.state.gravContent.pageSubHeading}/>
          </section>
          <section className="grey programmeStepsSection">
            <div className="programmeBannerWrapper">
              <div className="programmeGrid">
                <div className="programmeImageWrapper">
                  {mobileDevice
                    ? <img
                        src={gravURL + this.state.media.mobileBanner}
                        className="programmeBanner"
                        alt="NewImage Keto | Programme"
                        width="800px"
                        height="800px"/>
                    : <img
                      src={gravURL + this.state.media.desktopBanner}
                      className="programmeBanner"
                      alt="NewImage Keto | Programme"
                      width="800px"
                      height="800px"/>
}
                  <div className="imageGradientBlock"></div>
                </div>
                <ProgrammeSteps steps={this.state.gravContent.steps}/>
              </div>
            </div>
          </section>
          <div className="programmeGrid clearfix">
            <div className="programmeInfoFlex">
              <div className="programmeInfoBlock">
                <div className="matchHeight">
                  <Link to="/blog">
                    <ReactFitText compressor={0.6}>
                      <div className="weight700 line1">READ</div>
                    </ReactFitText>
                    <ReactFitText compressor={0.70}>
                      <div className="weight700 line1">TO SUCCEED</div>
                    </ReactFitText>
                    <ReactFitText compressor={1.1}>
                      <div>IMPORTANT INFO TO</div>
                    </ReactFitText>
                    <ReactFitText compressor={1.2}>
                      <div>GUIDE YOU THROUGH</div>
                    </ReactFitText>
                    <ReactFitText compressor={0.85}>
                      <div>YOUR JOURNEY</div>
                    </ReactFitText>
                  </Link>
                </div>
              </div>
              <div className="programmeInfoBlock">
                <div className="matchHeight">
                  <Link to="/tips">
                    <ReactFitText compressor={0.8}>
                      <div>Find ALL the</div>
                    </ReactFitText>
                    <ReactFitText compressor={0.8}>
                      <div>
                        <span className="weight700">Tips</span>
                        &
                        <span className="weight700">Tricks</span>
                      </div>
                    </ReactFitText>
                    <ReactFitText compressor={0.6}>
                      <div>here!</div>
                    </ReactFitText>
                  </Link>
                </div>
              </div>
              <div className="programmeInfoBlock imgBlock">
                <div className="matchHeight">
                  <a href="https://www.facebook.com/newimagesd2weightmanagement/">
                    <img
                      alt="Join the New_Image Keto community"
                      src={gravURL + '/uploads/images/programme/' + this.state.gravContent.infoBlock3Image}
                      width="600"
                      height="324"/>
                    <span className="blockButton">Join the</span>
                    <strong></strong>
                    <ReactFitText compressor={1}>
                      <div className="weight700">community</div>
                    </ReactFitText>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
export default requireAuth(ProgrammePage);