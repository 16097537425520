import React from 'react';
import {Link} from 'gatsby';
const ProgrammeSteps = (props) => {
  const steps = props
    .steps
    .map((item, key) => <li>
      <div className="programmeStep clearfix">
        <div className="programmeStepSphereWrapper">
          <div className="programmeStepSphere">
            <div className="sphereContent">
              <Link to={item.page}>
                <b>{item.stepHeading}</b>
                <i>{item.stepsubHeading}</i>
              </Link>
            </div>
          </div>
        </div>
        <div className="programmeStepTextWrapper">
          <div className="programmeStepText">
            <Link to={item.page}>{item.stepText}</Link>
          </div>
        </div>
      </div>
    </li>)
  return (
    <ul className="programmeStepsList">{steps}</ul>
  );
}
export default ProgrammeSteps;